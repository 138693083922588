import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import {
  postAppClientCredsList,
  postAppClientCredsAddEdit,
  postAppClientCredsDelete,
} from "../../redux/clients/action"
import {
  POST_CLIENTS_CREDS_ADD_EDIT,
  POST_CLIENTS_CREDS_DELETE,
} from "../../redux/actionTypes"
import AesUtil from "../../utils/AesUtil";
import SweetAlert from "sweetalert2";
import { PRIMARY_COLOR } from "../../constants";

const ClientCredentialsDialog = ({ open, onClose, clientItem }) => {

    const settingsData = useSelector(({ settings }) => settings.settingsData);

    const trans = settingsData.trans;

  const dispatch = useDispatch();
  const credsData = useSelector(
    ({ clients }) => clients.clientsCredsData
  );
  const addEditCredsData = useSelector(
    ({ clients }) => clients.addEditClientsCredsData
  );
  const deleteCredsData = useSelector(
    ({ clients }) => clients.deleteClientsCredsData
  );

  const [creds, setCreds] = useState([]);
  const [newCred, setNewCred] = useState({
    email: "",
    pass: "",
  });

  useEffect(() => {
    if (clientItem && open) {
      reloadCredsData();
    }
  }, [clientItem, open, dispatch]);

  useEffect(() => {
    if (credsData && credsData.creds) {
      setCreds(credsData.creds);
    }
  }, [credsData]);

  useEffect(() => {
    if (addEditCredsData) {
      if (addEditCredsData.status) {
        toast.success(addEditCredsData.message);
        clearCredData();
        reloadCredsData();
        dispatch({ type: POST_CLIENTS_CREDS_ADD_EDIT, payload: null });
      } else {
        toast.error(addEditCredsData.message);
      }
    }
  }, [addEditCredsData, dispatch]);

  useEffect(() => {
    if (deleteCredsData) {
      if (deleteCredsData.status) {
        toast.success(deleteCredsData.message);
        reloadCredsData();
        dispatch({ type: POST_CLIENTS_CREDS_DELETE, payload: null });
      } else {
        toast.error(deleteCredsData.message);
      }
    }
  }, [deleteCredsData, dispatch]);

  const clearCredData = () => {
    setNewCred({ email: "", pass: "" });
  };

  const reloadCredsData = () => {
    dispatch(postAppClientCredsList({ clientId: clientItem.id }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCred({ ...newCred, [name == "client_cred_e" ? 'email' : 'pass']: value });
  };

  const handleAddEditCred = () => {
    if (!isButtonEnabled()) return;

    dispatch(
      postAppClientCredsAddEdit({
        ...newCred,
        clientId: clientItem.id,
      })
    );
  };

  const handleDeleteCred = (credId) => {
    SweetAlert.fire({
      theme: "dark",
      title: trans.delete_record_title,
      text: trans.delete_record_desc,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: trans.delete_record_button,
      cancelButtonText: trans.cancel_btn,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteClientCred(credId);
      }
    });
  };

  const deleteClientCred = (credId) => {
    dispatch(
        postAppClientCredsDelete({
          clientId: clientItem.id,
          credId,
        })
      );
  }

  const isButtonEnabled = () => {
    return newCred.email.trim() !== "" && newCred.pass.trim() !== "";
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{trans.client_credntials_title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <DialogContent>
        <div style={{ fontWeight: "bold" }}>{trans.client_credntials_list}</div>
        <List>
          {creds.map((cred) => (
            <ListItem key={cred._id}>
              <ListItemText
                primary={`Email: ${AesUtil.decrypt(cred.email,clientItem.id)}`}
                secondary={`Pass: ${AesUtil.decrypt(cred.pass,clientItem.id)}`} // Mask password for security
              />
              <IconButton onClick={() => handleDeleteCred(cred._id)}>
                <CloseIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>


        <hr />
        <br/>
        <br/>
        
        <div style={{ fontWeight: "bold" }}>{trans.client_credntials_add_edit}</div>

        <br/>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label={trans.client_cred_email}
              name="client_cred_e"
              value={newCred.email}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
              autoComplete="new-password"

            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={trans.client_cred_pass}
              name="client_cred_p"
              value={newCred.pass}
              onChange={handleInputChange}
              type="password"
              fullWidth
              margin="normal"
              autoComplete="new-password"

            />
          </Grid>
        </Grid>

        <br/>
              <br/>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddEditCred}
            disabled={!isButtonEnabled()}
          >
            {trans.client_credntials_save}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ClientCredentialsDialog;
