// APP SETTINGS
export const APP_ID = "APP_ID";

//SETTINGS
export const GET_SETTINGS = "GET_SETTINGS";
export const ADD_EDIT_INIT_DATA = "ADD_EDIT_INIT_DATA";
export const GET_COUNTRIES = "GET_COUNTRIES";
export const BULK_EDIT_INIT_DATA = "BULK_EDIT_INIT_DATA";

//SCRIPTS
export const CATALOG_IMPORT_DATA = "CATALOG_IMPORT_DATA";

//USER
export const USER_TOKEN_SET = "USER_TOKEN_SET";
export const USER_ID_SET = "USER_ID_SET";
export const POST_SIGN_IN = "POST_SIGN_IN";
export const POST_SIGN_OUT = "POST_SIGN_OUT";
export const POST_USER_INFO = "POST_USER_INFO";


//CLIENTS
export const POST_CLIENTS_ALL = "POST_CLIENTS_ALL";
export const POST_CLIENTS_ADD = "POST_CLIENTS_ADD";
export const POST_CLIENTS_CREDS_ALL = "POST_CLIENTS_CREDS_ALL";
export const POST_CLIENTS_CREDS_ADD_EDIT = "POST_CLIENTS_CREDS_ADD_EDIT";
export const POST_CLIENTS_CREDS_DELETE = "POST_CLIENTS_CREDS_DELETE";

//DASHBOARD
export const POST_DASHBOARD_MAIN = "POST_DASHBOARD_MAIN";