const CryptoJS = require('crypto-js');

module.exports = {
  /**
   * Decrypts text using the given key
   * @param {String} ciphertext - The base64 encoded encrypted text
   * @param {String} key - The key used for decryption
   * @returns {String} - Decrypted (original) text
   */
  decrypt: function (ciphertext, key) {
   try {
    const bytes = CryptoJS.AES.decrypt(ciphertext, key);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
   } catch (e) {
    console.log("error,decrypt",e);
    return "";
   }
  }
};
