import {
  POST_CLIENTS_ALL,
  POST_CLIENTS_ADD,
  POST_CLIENTS_CREDS_ALL,
  POST_CLIENTS_CREDS_ADD_EDIT,
  POST_CLIENTS_CREDS_DELETE,
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  
export const postAppClientsAll = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.fromAdmin = true;

  const response = await axios.post(`appClient/listClients`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CLIENTS_ALL, payload: response.data});
};

    
export const postAppClientAdd = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.user_id = General.getUserId();

  const response = await axios.post(`appClient/addEditClient`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CLIENTS_ADD, payload: response.data});
};

    
export const postAppClientCredsList = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.user_id = General.getUserId();

  const response = await axios.post(`appClient/listCredentials`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CLIENTS_CREDS_ALL, payload: response.data});
};

    
export const postAppClientCredsAddEdit = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.user_id = General.getUserId();

  const response = await axios.post(`appClient/addEditCredential`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CLIENTS_CREDS_ADD_EDIT, payload: response.data});
};

export const postAppClientCredsDelete = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;
  postData.user_id = General.getUserId();

  const response = await axios.post(`appClient/deleteCredential`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_CLIENTS_CREDS_DELETE, payload: response.data});
};