import {
  POST_CLIENTS_ALL,
  POST_CLIENTS_ADD,
  POST_CLIENTS_CREDS_ALL,
  POST_CLIENTS_CREDS_ADD_EDIT,
  POST_CLIENTS_CREDS_DELETE,
} from "../actionTypes";

const INIT_STATE = {
  clientsData: null,
  addClientsData: null,
  editClientsData: null,
  clientsCredsData: null,
  addEditClientsCredsData: null,
  deleteClientsCredsData: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_CLIENTS_ALL:
      return {
        ...state,
        clientsData: action.payload,
      };
    case POST_CLIENTS_ADD:
      return {
        ...state,
        addClientsData: action.payload,
      };
    case POST_CLIENTS_CREDS_ALL:
      return {
        ...state,
        clientsCredsData: action.payload,
      };
    case POST_CLIENTS_CREDS_ADD_EDIT:
      return {
        ...state,
        addEditClientsCredsData: action.payload,
      };
    case POST_CLIENTS_CREDS_DELETE:
      return {
        ...state,
        deleteClientsCredsData: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
