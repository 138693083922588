import React, {Fragment,useEffect,useState, useRef} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Route, Routes,Navigate} from 'react-router-dom';
import Home from './home';
import Login from './login';
import PrivateRoute from './privateRoute';
import {Provider} from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import history from '../history';
import { toast } from 'react-toastify';
import * as General from '../helpers/General'
import * as Store from '../helpers/Store';

import NoAppIdComponent from './global/no-content/NoAppIdComponent';
import { Button } from '@mui/material';
import { PUSH_TYPES } from '../constants';

import notificationSound from '../assets/sound/new-order.mp3';



const CustomToastWithLink = (title,body,typeId,type,settingsData) => (
  <div style={{ backgroundColor: settingsData.initData.alert_color_bg, padding: "16px", borderRadius: "8px" }}>
  <div className="fontBold">{title}</div>

  <div style={{ fontSize: "15px", marginTop: "10px", marginBottom: "10px" }}>
    {body}
  </div>
  {type === PUSH_TYPES.NEW_ORDER ? (
    <Button
      variant="contained"
      component="a" // Makes the button act like an anchor tag
      href={`/${settingsData.app_id}/app/orders/${typeId}`}
      style={{backgroundColor: settingsData.initData.alert_color_text, color: settingsData.initData.alert_color_bg, textDecoration: "none" }}
      target="_blank"
      rel="noopener noreferrer"
    >
     {settingsData.trans.new_order_button}
    </Button>
  ): null}
</div>
);

const MainApp = () => {


  const settingsData = useSelector(({ settings }) => settings.settingsData);

  //NOTIFICATION
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);


  //ENDOFNOTIFICATION

  const [darkModeChecked, setDarkModeChecked] = useState(false);

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Disables uppercase transformation globally
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#D21F3C",
      },
      secondary: {
        main: "#757575",
      },
      mode: darkModeChecked ? 'dark' : 'light',
      ...(!darkModeChecked
      ? {
      
          background: {
            default: "#f5f7fa",
            paper: "#ffffff",
          },
        }
      : {
        primary: {
          main: "#D21F3C",
        },
          secondary: {
            main: "#f5f7fa",
          },
          background: {
            default: "#1F2937",
            paper: "#030712",
          },
        }),
    },
  });

  const onModeChange = (isDarkChecked) => {
    setDarkModeChecked(isDarkChecked);
  }

  useEffect(()=>{
    setDarkModeChecked(General.getLightsMode() == "dark")
    },[]);
  
  return (
    <div> 
           
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
      <Fragment>
      <Router location={history.location} navigator={history}>
          <Routes>
            <Route path='/' element={<PrivateRoute path={'/'}><Navigate to="app"></Navigate></PrivateRoute>} /> 
            <Route path='/' element={<PrivateRoute path={'/'}><Navigate to="app"></Navigate></PrivateRoute>}/> 
            <Route path='/app/*' element={<PrivateRoute path={'app/*'}><Home onModeChange={onModeChange}/></PrivateRoute>}/> 
            <Route path='/login' element={<PrivateRoute path={'/login'}><Login /></PrivateRoute>}/>
          </Routes>
      </Router>
      </Fragment>
      </ThemeProvider>
      <ToastContainer/>
      </LocalizationProvider>
    </div>
  );
};

export default MainApp;
